<template>
  <div class="footer">
    <li>
      <ul class="first"><a style="color: #fff;text-decoration: none" href="https://beian.miit.gov.cn/">©浙ICP备2023002045号</a></ul>
      <ul class="middle"><i class="el-icon-time">2022-2023 PERSEVERE-BLOG</i></ul>
      <ul><i class="el-icon-star-off">星光不问赶路人，岁月不负有心人</i></ul>
    </li>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>
q
<style scoped>
.first_ul {
  font-weight: bold;
}

li {
  list-style: none;
}

.footer {
  width: 100%;
  display: inline-block;
  height: 100px;
  background-color: #e41b14;
  color: #fff;
}

.footer li {
  width: 200px;
  margin: 0 auto;
  height: 20px;
  line-height: 20px;
  font-size: 10px;
  text-align: center;
}

.footer .first {
  margin-top: 30px;
}
</style>

import { render, staticRenderFns } from "./BasicLayout.vue?vue&type=template&id=6739d95c&scoped=true&"
import script from "./BasicLayout.vue?vue&type=script&setup=true&lang=ts&"
export * from "./BasicLayout.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./BasicLayout.vue?vue&type=style&index=0&id=6739d95c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6739d95c",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./Sort.vue?vue&type=template&id=176107d8&scoped=true&"
import script from "./Sort.vue?vue&type=script&lang=js&"
export * from "./Sort.vue?vue&type=script&lang=js&"
import style0 from "./Sort.vue?vue&type=style&index=0&id=176107d8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "176107d8",
  null
  
)

export default component.exports